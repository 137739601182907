import {
  PLANS,
  BOOKPLAN,
  PLANDETAILS,
  CLEARSTATUS,
  RESETBOOKPLAN,
  RESET_JOIN_WAITLIST_COURSE,
  JOIN_WAITLIST_COURSE,
  RESETBOOKPURCHASE,
  BOOKPURCHASE,
  COURSES,
  BOOKCOURSES,
  COURSEDETAILS,
  RESETBOOKCOURSES,
  TAXRATES,
  ADD_SHARED_MEMBERS,
  STRIPE_SESSION_PLAN,
  THREE_D_SECURE_PLAN
} from 'Constants'
import { QUICK_PAY_APPOINTMENTS, SALE_ID } from '../constants'

const INITIAL_STATE = {
  planDetail: null,
  services: null,
  bookPlanDetail: null,
  waitlistCourseDetail: null,
  bookPurchaseDetail: null,
  courseDetail: null,
  courses: null,
  bookCoursesDetail: null,
  taxRateDetail: null,
  sharedMembers: [],
  stripeSessionData: null,
  threeDSecureData: null,
  quickPayData: null,
  saleId: null
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PLANS:
      return { ...state, services: action.payload }
    case PLANDETAILS:
      return { ...state, planDetail: action.payload }
    case BOOKPLAN:
      return { ...state, bookPlanDetail: action.payload }
    case RESETBOOKPLAN:
      return { ...state, bookPlanDetail: null }
    case JOIN_WAITLIST_COURSE:
      return { ...state, waitlistCourseDetail: action.payload }
    case RESET_JOIN_WAITLIST_COURSE:
      return { ...state, waitlistCourseDetail: null }
    case BOOKPURCHASE:
      return { ...state, bookPurchaseDetail: action.payload }
    case RESETBOOKPURCHASE:
      return { ...state, bookPurchaseDetail: null }
    case QUICK_PAY_APPOINTMENTS:
      return { ...state, quickPayData: action.payload }
    case COURSES:
      return { ...state, courses: action.payload }

    case BOOKCOURSES:
      return { ...state, bookCoursesDetail: action.payload }
    case COURSEDETAILS:
      return { ...state, courseDetail: action.payload }

    case RESETBOOKCOURSES:
      return { ...state, bookCoursesDetail: null }
    case CLEARSTATUS:
      return {
        ...state,
        planDetail: null,
        courseDetail: null,
        taxRateDetail: null
      }
    case TAXRATES:
      return { ...state, taxRateDetail: action.payload }
    case ADD_SHARED_MEMBERS:
      return { ...state, sharedMembers: action.payload }
    case STRIPE_SESSION_PLAN:
      return { ...state, stripeSessionData: action.payload }
    case THREE_D_SECURE_PLAN:
      return { ...state, threeDSecureData: action.payload }
    case SALE_ID:
      return { ...state, saleId: action.payload }
    default:
      return state
  }
}
