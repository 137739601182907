import React from 'react'
import styled from 'styled-components'
import CaretArrowDown from 'Components/Icons/CaretArrowDown'
import RightTick from 'Components/Icons/RightTick'
import Account from '../Icons/Account'
import useNavbar from './useNavbar'

const DropdownContainer = styled.div`
  position: relative;
  z-index: 10000;
`

const DropdownButton = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 11px;
  margin: 34px 0 12px;
  padding: 12px 0;
  border-radius: 6px;
  background-color: #f0f1f5;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: #0d1f2d;
  path {
    fill: #0d1f2d;
  }
`

const DropdownMenu = styled.div`
  width: 100%;
  padding: 24px 16px 4px;
  box-sizing: border-box;
  position: absolute;
  top: 51px;
  z-index: 100;
  border-radius: 6px;
  box-shadow: 0 12px 24px 0 rgba(52, 52, 52, 0.1),
    0 8px 16px 0 rgba(51, 51, 51, 0.1);
  background-color: white;
`

const DropdownOption = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  text-align: left;
  color: #0d1f2d;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  path {
    fill: #0d1f2d;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const Mweb = ({ items }) => {
  const {
    handleItemClick,
    isOpenDropdown,
    selectedItemIndex,
    toggleDropdown,
    isLoggedIn,
    commonMenuItems,
    loginMenuItem
  } = useNavbar(items, true)

  return (
    <DropdownContainer>
      <DropdownButton onClick={toggleDropdown}>
        {items[selectedItemIndex]?.title}
        {<CaretArrowDown expanded={isOpenDropdown} />}
      </DropdownButton>
      {isOpenDropdown && (
        <DropdownMenu>
          {commonMenuItems.map(dropdownItem => {
            return (
              <DropdownOption
                key={dropdownItem.slug}
                onClick={() => handleItemClick(dropdownItem.index)}
              >
                <Title>{dropdownItem.title}</Title>
                {dropdownItem.index === selectedItemIndex && <RightTick />}
              </DropdownOption>
            )
          })}
          <DropdownOption onClick={() => handleItemClick(loginMenuItem?.index)}>
            {isLoggedIn ? (
              <Title>
                <Account />
                <span>
                  {JSON.parse(localStorage.getItem('contact'))?.first_name ??
                    loginMenuItem?.title}
                </span>
              </Title>
            ) : (
              <>{loginMenuItem?.title}</>
            )}
            {loginMenuItem.index === selectedItemIndex && <RightTick />}
          </DropdownOption>
        </DropdownMenu>
      )}
    </DropdownContainer>
  )
}

export default Mweb
