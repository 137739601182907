import React from 'react'
import styled from 'styled-components'

//Animate CaretDown rather than rendering another CaretArrowUp styled-component
const CaretArrowDown = styled.svg`
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: ${props => (props.expanded ? `rotate(180deg)` : `rotate(0deg)`)};
`

export default ({ className, ...rest }) => (
  <CaretArrowDown
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    className={className}
    {...rest}
  >
    <path
      fill="#D6D6D6"
      fillRule="evenodd"
      d="M15 6.127c.001.346-.135.674-.37.892l-6 5.521a.905.905 0 0 1-1.27 0L1.362 6.825c-.425-.404-.483-1.126-.13-1.612.354-.486.985-.553 1.41-.149l5.36 5.11 5.36-4.938a.901.901 0 0 1 1.069-.154c.352.191.574.6.57 1.045z"
    />
  </CaretArrowDown>
)
