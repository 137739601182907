import { LOGIN, LOGOUT, SIGNUP } from 'Constants'

export default function(
  state = localStorage.getItem('customerkey') !== null,
  action
) {
  switch (action.type) {
    case LOGIN:
      return localStorage.getItem('customerkey') !== null
    case SIGNUP:
      return localStorage.getItem('customerkey') !== null
    case LOGOUT:
      localStorage.clear()
      return false
    default:
      return state
  }
}
