import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { Switch } from 'react-router-dom'
import Loader from 'Components/Loader'

const RegionAndLocationRoute = ({
  component: Component,
  loginRequired = false,
  isLoggedIn,
  regions,
  extendUrls = [],
  match,
  hasLocUrl = false,
  ...rest
}) => {
  let redirect_url = match.path
  const [regionId, setRegionId] = useState(null)

  if (regions) {
    if (redirect_url[redirect_url.length - 1] !== '/') {
      redirect_url = redirect_url + '/'
    }
  }

  useEffect(() => {
    const id = localStorage.getItem('region_id')
    setRegionId(id)
  }, [])

  if (regions?.length === 0 || !regionId) {
    return (
      <div>
        <Loader />
      </div>
    )
  }

  return (
    <Switch>
      {loginRequired && !isLoggedIn && (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: rest.location.pathname + rest.location.search }
          }}
        />
      )}

      {((loginRequired && isLoggedIn) || !loginRequired) && regions && (
        <Route
          path={match.url}
          {...rest}
          render={props => (
            <Redirect to={{ pathname: `${redirect_url}r/${regions[0].id}` }} />
          )}
        />
      )}

      {((loginRequired && isLoggedIn) || !loginRequired) && hasLocUrl && (
        <Route
          path={`${redirect_url}r/:region/loc/:center`}
          {...rest}
          component={Component}
        />
      )}
      {((loginRequired && isLoggedIn) || !loginRequired) &&
        hasLocUrl &&
        extendUrls.length > 0 &&
        extendUrls.map(url => (
          <Route
            key={url}
            path={`${redirect_url}r/:region/loc/:center/${url}`}
            {...rest}
            component={Component}
          />
        ))}
      {((loginRequired && isLoggedIn) || !loginRequired) &&
        extendUrls &&
        extendUrls.map(url => (
          <Route
            key={url}
            path={`${redirect_url}r/:region/${url}`}
            {...rest}
            component={Component}
          />
        ))}
      {((loginRequired && isLoggedIn) || !loginRequired) && (
        <Route
          path={`${redirect_url}r/:region`}
          {...rest}
          component={Component}
        />
      )}
    </Switch>
  )
}

const mapStateToProps = state => {
  return {
    regions: state.centerreducer.regionsDetails
      ? state.centerreducer.regionsDetails.regions
      : null,
    isLoggedIn: state.isLoggedReducer
  }
}

export default connect(mapStateToProps, null)(RegionAndLocationRoute)
