/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react'
import {
  Route,
  Switch,
  useLocation,
  useHistory,
  Redirect
} from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import loadable from '@loadable/component'
import ErrorBoundaryPage from 'Pages/ErrorBoundaryPage'
import Loader from 'Components/Loader'
import { inIframe } from 'Utils/helper'
import RegionAndLocationRoute from './RegionAndLocationRoute'
import { connect } from 'react-redux'
import { clearFromEvent } from 'Actions/event_action'
import { isJson } from '../components/Common'
import * as Sentry from '@sentry/react'
import useQuery from '../custom-hooks/useQuery'

const PrivateRoute = loadable(() => import('./privateRoute'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})

const NotFound = loadable(() => import('Pages/NotFound'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const CreateAccount = loadable(() => import('Pages/CreateAccount'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const Login = loadable(() => import('Pages/Login'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const ResetPassword = loadable(() => import('Pages/ResetPassword'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const CreatePassword = loadable(() => import('Pages/CreatePassword'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const ClassSchedule = loadable(() => import('Pages/ClassSchedule'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const ClassDetail = loadable(() => import('Pages/ClassDetail'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const Membership = loadable(() => import('Pages/Membership'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const Courses = loadable(() => import('Pages/Courses'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const AddCard = loadable(() => import('Pages/AddCard'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const Checkout = loadable(() => import('Pages/Checkout'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const StripeCheckout = loadable(() => import('Pages/Checkout/StripeCheckout'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const AdyenRedirect = loadable(() => import('Pages/AdyenRedirect'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const Unsubscribe = loadable(() => import('Pages/Unsubscribe'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const Unsubscribed = loadable(() => import('Pages/Unsubscribed'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const Resubscribed = loadable(() => import('Pages/Resubscribed'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const StripeRedirect = loadable(() => import('Pages/StripeRedirect'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const ConfirmPayment = loadable(() => import('Pages/ConfirmPayment'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const PaymentFailed = loadable(() => import('Pages/PaymentFailed'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const MyAccount = loadable(() => import('Pages/MyAccount'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const EditProfile = loadable(() => import('Pages/EditProfile'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const RelatedContacts = loadable(() => import('Pages/RelatedContacts'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const EmergencyContacts = loadable(() => import('Pages/EmergencyContacts'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const AddRelatedContact = loadable(() => import('Pages/AddRelatedContact'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const AddEmergencyContact = loadable(
  () => import('Pages/AddEmergencyContact'),
  {
    fallback: Loader({
      pastDelay: true,
      error: false,
      timedOut: false
    })
  }
)

const MyBookings = loadable(() => import('Pages/MyBookings'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})

const RentedVods = loadable(() => import('Pages/RentedVods'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})

const MyPayments = loadable(() => import('Pages/MyPayments'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})

const MyMemberships = loadable(() => import('Pages/MyMemberships'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})

const PaymentMethod = loadable(() => import('Pages/PaymentMethod'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})

const MembershipPurchase = loadable(
  () => import('Components/MembershipPurchase'),
  {
    fallback: Loader({
      pastDelay: true,
      error: false,
      timedOut: false
    })
  }
)
const CoursePurchase = loadable(() => import('Components/CoursePurchase'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})

const ThankYou = loadable(() => import('Pages/ThankYou'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})

const Processing = loadable(() => import('Pages/Processing'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})

const Tos = loadable(() => import('Pages/Tos'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const Appointments = loadable(() => import('Pages/Appointments'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const AppointmentDetail = loadable(() => import('Pages/AppointmentDetail'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const WebViewAddCardAdyen = loadable(
  () => import('Pages/WebViewAddCardAdyen'),
  {
    fallback: Loader({
      pastDelay: true,
      error: false,
      timedOut: false
    })
  }
)
const VodSingle = loadable(() => import('Pages/VodSingle'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const VodPlaylists = loadable(() => import('Pages/VodPlaylists'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const VodPlaylistSingle = loadable(() => import('Pages/VodPlaylistSingle'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const ReferAndEarn = loadable(() => import('Pages/ReferAndEarn'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const Waiver = loadable(() => import('Pages/Waiver'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const SignDocument = loadable(() => import('Pages/SignDocument'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const AdyenCheckoutMobile = loadable(
  () => import('Pages/AdyenCheckoutMobile'),
  {
    fallback: Loader({
      pastDelay: true,
      error: false,
      timedOut: false
    })
  }
)

const CustomForms = loadable(() => import('Pages/CustomForms'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})
const Products = loadable(() => import('Pages/Products'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})

const ProductDetail = loadable(() => import('Pages/ProductDetail'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})

const Cart = loadable(() => import('Pages/Cart'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})

const VodRent = loadable(() => import('Pages/VodRent'), {
  fallback: Loader({
    pastDelay: true,
    error: false,
    timedOut: false
  })
})

let parentOrigin = null

const subscriberForHeight = () => {
  let lastScrollHeight = window.document.body.scrollHeight
  return mutations => {
    if (lastScrollHeight !== document.body.scrollHeight) {
      lastScrollHeight = document.body.scrollHeight
      window.parent.postMessage(
        JSON.stringify({
          type: 'ReceiveMyHeight',
          message: { height: lastScrollHeight }
        }),
        parentOrigin
      )
    }
  }
}

const Routes = ({ from_event, clearFromEvent, ...props }) => {
  const location = useLocation()
  let history = useHistory()
  const queryParams = useQuery()

  useEffect(() => {
    const rwg = queryParams.get('rwg_token')
    if (rwg) {
      if (rwg) {
        const rwgCookie = localStorage.getItem('rwg_token')

        if (!rwgCookie) {
          localStorage.setItem('rwg_token', rwg)
        } else if (rwgCookie !== rwg) {
          localStorage.setItem('rwg_token', rwg)
        } else {
        }
      }
    }
  }, [queryParams])

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      history.listen((location, action) => {
        // check for sw updates on route change
        navigator.serviceWorker
          .getRegistrations()
          .then(regs => regs.forEach(reg => reg.update()))
      })
    }
  }, [])

  useEffect(() => {
    if (inIframe()) {
      let observer, resizeObserver
      const handleMessage = e => {
        const { type, message } = isJson(e.data) ? JSON.parse(e.data) : e.data
        switch (type) {
          case 'SeeMyOrigin':
            // should verify parentOrigin
            parentOrigin = e.origin
            localStorage.setItem('parentOrigin', parentOrigin)
            window.parent.postMessage(
              JSON.stringify({
                type: 'ReceiveMyHeight',
                message: { height: window.document.body.scrollHeight }
              }),
              parentOrigin
            )
            if (!observer) {
              const observerConfig = {
                attributes: true,
                childList: true,
                characterData: true,
                subtree: true
              }
              observer = new MutationObserver(subscriberForHeight())
              observer.observe(window.document.body, observerConfig)

              if ('ResizeObserver' in window) {
                resizeObserver = new ResizeObserver(subscriberForHeight())
                resizeObserver.observe(window.document.body)
              }
            }
            break
          case 'ChangePath':
            parentOrigin = e.origin

            history.push(message.path)
            break
          case 'SeeMyLink':
            localStorage.setItem('origin', message)
            break
          case 'GetRWGToken':
            localStorage.setItem('rwg_token', message)
            break
          default:
            break
        }
      }

      window.addEventListener('message', handleMessage, false)
      return () => {
        window.removeEventListener('message', handleMessage, false)
        if (observer) {
          observer.disconnect()
        }
      }
    }
  }, [])

  useEffect(() => {
    if (inIframe()) {
      window.parent.postMessage(
        JSON.stringify({
          type: 'ShowOrigin'
        }),
        '*'
      )
    }
  }, [])

  useEffect(() => {
    if (inIframe() && parentOrigin) {
      window.parent.postMessage(
        JSON.stringify({
          type: 'RouteChanged',
          message: {
            path: location.pathname + location.search
          }
        }),
        parentOrigin
      )
    }
    if (from_event.check === true) {
      if (
        location.pathname.includes('pricing') === false &&
        location.pathname.includes('addcard') === false
      ) {
        clearFromEvent()
      }
    }
  }, [location])

  const onError = error => {
    Sentry.captureException(error)
  }

  return (
    <div className="App">
      <ErrorBoundary onError={onError} FallbackComponent={ErrorBoundaryPage}>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/create-account" exact component={CreateAccount} />
          <Route path="/reset-password" exact component={ResetPassword} />
          <Route path="/create-password" exact component={CreatePassword} />
          <Route path="/stripe-redirect" exact component={StripeRedirect} />
          <Route path="/confirm-payment" exact component={ConfirmPayment} />
          <Route path="/payment-failed" exact component={PaymentFailed} />
          <Route path="/adyen-redirect" exact component={AdyenRedirect} />
          <Route path="/unsubscribe" exact component={Unsubscribe} />
          <Route path="/unsubscribed" exact component={Unsubscribed} />
          <Route path="/resubscribed" exact component={Resubscribed} />
          {/* <Route path="/vod" exact component={VodSingle} /> */}

          {/*-----------------------------------------------------------------*/}

          <Route
            path="/vod/playlist/:pid/video/:vid"
            render={props => (
              <RegionAndLocationRoute
                {...props}
                component={VodSingle}
                hasLocUrl={true}
                exact
              />
            )}
          />
          <Route
            path="/vod/playlist/:pid"
            render={props => (
              <RegionAndLocationRoute
                {...props}
                component={VodPlaylistSingle}
                hasLocUrl={true}
                exact
              />
            )}
          />
          <Route
            path="/vod"
            render={props => (
              <RegionAndLocationRoute
                extendUrls={['']}
                {...props}
                component={VodPlaylists}
                hasLocUrl={true}
                exact
              />
            )}
          />

          <Route
            path="/class-schedule"
            render={props => (
              <RegionAndLocationRoute
                {...props}
                exact
                component={ClassSchedule}
              />
            )}
          />

          <Route
            path="/appointment"
            render={props => (
              <RegionAndLocationRoute
                {...props}
                exact
                component={Appointments}
                hasLocUrl={true}
              />
            )}
          />

          <Route
            path="/adyen-add-card"
            render={props => (
              <RegionAndLocationRoute
                {...props}
                exact
                component={WebViewAddCardAdyen}
                hasLocUrl={true}
              />
            )}
          />

          <Route
            path="/appointment-detail/:eventId"
            render={props => (
              <RegionAndLocationRoute
                //what does step do ?
                extendUrls={[':step']}
                exact
                loginRequired={false}
                {...props}
                hasLocUrl={true}
                component={AppointmentDetail}
              />
            )}
          />

          <Route
            path="/pricing/buy"
            render={props => (
              <RegionAndLocationRoute
                {...props}
                exact
                loginRequired={false}
                component={MembershipPurchase}
                hasLocUrl={true}
              />
            )}
          />
          <Route
            path="/course/buy/:eventId"
            render={props => (
              <RegionAndLocationRoute
                {...props}
                exact
                loginRequired={false}
                component={CoursePurchase}
                hasLocUrl={true}
              />
            )}
          />

          <Route
            path="/pricing"
            render={props => (
              <RegionAndLocationRoute
                {...props}
                exact
                component={Membership}
                hasLocUrl={true}
              />
            )}
          />
          <Route
            path="/courses"
            render={props => (
              <RegionAndLocationRoute
                {...props}
                exact
                component={Courses}
                hasLocUrl={true}
              />
            )}
          />

          <Route
            path="/class-detail/:eventId"
            render={props => (
              <RegionAndLocationRoute
                extendUrls={[':step']}
                exact
                loginRequired={false}
                {...props}
                component={ClassDetail}
              />
            )}
          />

          <Route
            path="/tos"
            render={props => (
              <RegionAndLocationRoute
                {...props}
                exact
                component={Tos}
                hasLocUrl={true}
              />
            )}
          />

          <Route path="/document" exact component={Waiver} />
          <Route path="/document/sign" exact component={SignDocument} />
          <Route path="/adyencheckout" exact component={AdyenCheckoutMobile} />
          <Route path="/public/forms/:formId" exact component={CustomForms} />

          <Route path="/thank-you" exact component={ThankYou} />
          <PrivateRoute
            path="/addcard/r/:region/loc/:center"
            exact
            component={AddCard}
          />
          <PrivateRoute
            path="/checkout/r/:region/loc/:center/sessionId/:sessionId"
            exact
            component={Checkout}
          />
          <Route path="/processing" exact component={Processing} />
          <PrivateRoute
            path="/checkout/stripe/sessionId/:sessionId"
            exact
            component={StripeCheckout}
          />
          <PrivateRoute path="/forms/:formId" exact component={CustomForms} />

          {/*<PrivateRoute*/}
          {/*  path="/pricing/buy/r/:region/loc/:center"*/}
          {/*  exact*/}
          {/*  component={MembershipPurchase}*/}
          {/*/>*/}

          {/*-----------------------------------------------------------------*/}

          <PrivateRoute path="/addcard/r/:region/" exact component={AddCard} />

          <PrivateRoute path="/my-account" exact component={MyAccount} />
          <PrivateRoute
            path="/my-account/edit-profile"
            exact
            component={EditProfile}
          />
          <PrivateRoute
            path="/my-account/related-contacts"
            exact
            component={RelatedContacts}
          />
          <PrivateRoute
            path="/my-account/emergency-contacts"
            exact
            component={EmergencyContacts}
          />
          <PrivateRoute
            path="/my-account/add-related-contacts"
            exact
            component={AddRelatedContact}
          />
          <PrivateRoute
            path="/my-account/add-emergency-contacts"
            exact
            component={AddEmergencyContact}
          />
          <PrivateRoute
            path="/my-account/refer-and-earn"
            exact
            component={ReferAndEarn}
          />
          <PrivateRoute
            path="/my-account/bookings"
            exact
            component={MyBookings}
          />
          <PrivateRoute
            path="/my-account/video-library"
            exact
            component={RentedVods}
          />
          {/* <PrivateRoute
          path="/my-account/payment-method"
          exact
          component={PaymentMethod}
        /> */}
          <Route
            path="/my-account/payment-method"
            render={props => (
              <RegionAndLocationRoute
                {...props}
                exact
                loginRequired={true}
                component={PaymentMethod}
                hasLocUrl={true}
              />
            )}
          />
          <Route
            path="/my-account/purchases"
            render={props => (
              <RegionAndLocationRoute
                {...props}
                exact
                loginRequired={true}
                component={MyPayments}
                hasLocUrl={true}
              />
            )}
          />
          <Route
            path="/my-account/memberships"
            render={props => (
              <RegionAndLocationRoute
                {...props}
                exact
                loginRequired={true}
                component={MyMemberships}
                hasLocUrl={true}
              />
            )}
          />
          <PrivateRoute path="/appointments" exact component={Appointments} />

          <Route
            path="/products"
            render={props => (
              <RegionAndLocationRoute
                {...props}
                exact
                component={Products}
                hasLocUrl={true}
              />
            )}
          />

          <Route
            path="/product-detail"
            render={props => (
              <RegionAndLocationRoute
                {...props}
                extendUrls={[':id']}
                exact
                component={ProductDetail}
                hasLocUrl={false}
              />
            )}
          />

          <Route
            path="/cart"
            render={props => (
              <RegionAndLocationRoute
                {...props}
                exact
                component={Cart}
                hasLocUrl={true}
              />
            )}
          />

          <Route
            path="/vod-rent/:id/:type"
            render={props => (
              <RegionAndLocationRoute
                {...props}
                exact
                component={VodRent}
                hasLocUrl={true}
              />
            )}
          />

          <Redirect exact from="/" to="/class-schedule" />
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </ErrorBoundary>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    from_event: state.eventreducer.from_event
  }
}

export default connect(mapStateToProps, {
  clearFromEvent
})(Routes)
