import { combineReducers } from 'redux'
import accountreducer from './account_reducer'
import centerreducer from './center_reducer'
import cartReducer from './cart_reducer'
import customFormReducer from './customForm_reducer'
import eventreducer from './event_reducer'
import appointmentsReducer from './appointments_reducer'
import planreducer from './plan_reducer'
import addcardReducer from './addcard_reducer'
import addrcReducer from './addrcReducer'
import addEmergencyContactReducer from './addEmergencyContactReducer'
import isLoggedReducer from './isLogged_reducer'
import vodReducer from './vod_reducer'
import giftCardReducer from './giftcard_reducer'
import waiverReducer from './waiver_reducer'
import websiteReducer from './website_reducer'

const allreducers = combineReducers({
  accountreducer,
  centerreducer,
  cartReducer,
  customFormReducer,
  eventreducer,
  appointmentsReducer,
  planreducer,
  addcardReducer,
  addrcReducer,
  addEmergencyContactReducer,
  isLoggedReducer,
  vodReducer,
  giftCardReducer,
  waiverReducer,
  websiteReducer
})

export default allreducers
