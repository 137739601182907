import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Spinner from 'Components/Spinner'
import { hexToRgbA } from '../../utils/helper'

const size = {
  sm: {
    padding: `0.625em 1.5em`
  },
  md: {
    padding: `0.625em 3em`
  },
  lg: {
    padding: '0.75em 5em'
  },
  sleek: {
    padding: '10px 40px'
  },
  slim: {
    padding: '10px 12px'
  }
}

const ButtonContainer = styled.button`
  border-radius: 2px;
  background-color: ${props => props.theme.color.brand};
  padding: ${props =>
    props.size === 'sm'
      ? size.sm.padding
      : props.size === 'md'
      ? size.md.padding
      : size.lg.padding};
  font-size: 1rem;
  font-weight: bold;
  color: ${props => props.theme.color.bg};
  line-height: 20px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: pointer;
  min-width: 50px;
  min-height: 48px;
  width: max-content;
  text-align: center;
  &:hover:not(:disabled) {
    opacity: 0.85;
  }
  &:disabled {
    cursor: not-allowed;
  }
  border: none;
  white-space: nowrap;
  user-select: none;
  @media only screen and (max-width: ${props => props.theme.breakpoint.sm}px) {
    padding: ${size.sm.padding};
  }
`

const ButtonAlternative = styled(ButtonContainer)`
  border: 2px solid ${props => props.theme.color.brand};
  color: ${props => props.theme.color.brand};
  background-color: ${props => props.theme.color.bg};
  border-color: ${props => props.theme.color.brand};
  text-align: center;
  &:hover:not(:disabled) {
    border-color: ${props => hexToRgbA(props.theme.color.brand, 0.2)};
    background-color: ${props => props.theme.color.brand};
    color: ${props => props.theme.color.bg};
  }
`

const ButtonText = styled(ButtonAlternative)`
  border: none;
`

const Button = ({
  disabled,
  loading,
  children,
  size = 'sm',
  type = 'normal',
  onClick,
  style = {},
  ...rest
}) => {
  return (
    <ButtonContainer
      style={style}
      disabled={disabled}
      as={
        (type === 'alternative' && ButtonAlternative) ||
        (type === 'text' && ButtonText) ||
        null
      }
      size={size}
      onClick={onClick}
      {...rest}
    >
      {loading ? <Spinner /> : children}
    </ButtonContainer>
  )
}

Button.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'sleek', 'slim']),
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['default', 'alternative', 'text']),
  children: PropTypes.node
}

export default Button
