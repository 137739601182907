import React from 'react'
import { useLocation } from 'react-router-dom'

// A custom hook that builds on useLocation to parse the query string
const useQuery = () => {
  const location = useLocation()
  return React.useMemo(
    () => new URLSearchParams(decodeURIComponent(location.search)),
    [decodeURIComponent(location.search)]
  )
}

export default useQuery
