import { CENTER, SEARCHCENTER, REGIONS } from 'Constants'

const INITIAL_STATE = {
  centerDetails: null,
  searchcenters: null,
  regionsDetails: null,
  centersById: null,
  centersByRegion: null,
  regionsById: null
}

export default function(state = INITIAL_STATE, action) {
  let centersById = {}
  let centersByRegion = {}
  let regionsById = {}
  switch (action.type) {
    case CENTER:
      return { ...state, centerDetails: action.payload }
    case SEARCHCENTER:
      return { ...state, searchcenters: action.payload }
    case REGIONS:
      if (action.payload.regions) {
        action.payload.regions.forEach(region => {
          regionsById[region.id] = region
          centersByRegion[region.id] = []
          region.centers.forEach(center => {
            centersById[center.id] = center
            centersByRegion[region.id].push(center.id)
          })
        })
      }
      return {
        ...state,
        regionsDetails: action.payload,
        centersById,
        centersByRegion,
        regionsById
      }
    default:
      return state
  }
}
