import {
  APPOINTMENTS,
  APPOINTMENTSPRICING,
  APPOINTMENT_TYPES,
  APPOINTMENT_TRAINERS,
  CLEARSTATUS,
  FETCH_APPOINTMENTS,
  APPOINTMENTSTATE,
  DONE_FETCHING_APPOINTMENTS,
  BOOKPT
} from 'Constants'

const INITIAL_STATE = {
  appointments: null,
  appointmentsPricing: {},
  appointmentState: null,
  appointmentTypes: [],
  trainers: [],
  isFetchingAppointments: false,
  bookPtDetail: null,
  ptSlotDates: []
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload
      }
    case APPOINTMENTSTATE:
      return { ...state, appointmentState: action.payload }

    case FETCH_APPOINTMENTS:
      return { ...state, isFetchingAppointments: true }

    case DONE_FETCHING_APPOINTMENTS:
      return { ...state, isFetchingAppointments: false }

    case APPOINTMENTSPRICING:
      return { ...state, appointmentsPricing: action.payload }

    case APPOINTMENT_TYPES:
      return { ...state, appointmentTypes: action.payload }

    case APPOINTMENT_TRAINERS:
      return { ...state, trainers: action.payload }
    case BOOKPT:
      return { ...state, bookPtDetail: action.payload }

    case CLEARSTATUS:
      return {
        ...state,
        appointments: [],
        appointmentsPricing: [],
        bookPtDetail: null
      }

    default:
      return state
  }
}
