import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { SELECTED_NAVBAR_INDEX } from 'Constants'
import useToggle from 'CustomHooks/useToggle'
import { MENU_ITEMS } from '../../constants'

const privateItems = [MENU_ITEMS.myAccount, MENU_ITEMS.login]

const useNavbar = (items, isDropdown) => {
  const [isOpenDropdown, toggleDropdown] = useToggle()
  const location = useLocation()
  const history = useHistory()
  const [selectedItemIndex, setSelectedItemIndex] = useState(0)
  const manuallyRouteChanged = useRef(false)
  const isLoggedIn = useSelector(store => store.isLoggedReducer)
  const centers = useSelector(store => store.centerreducer.centersById)

  const commonMenuItems = useMemo(
    () => items.filter(item => !privateItems.includes(item.slug)),
    [items]
  )
  const loginMenuItem = useMemo(
    () =>
      isLoggedIn
        ? items.find(item => item.slug === MENU_ITEMS.myAccount)
        : items.find(item => item.slug === MENU_ITEMS.login),
    [items, isLoggedIn]
  )

  const changeRoute = useCallback((index, looseMatch = false) => {
    setSelectedItemIndex(index)
    if (index === -1) return

    if (!looseMatch) {
      manuallyRouteChanged.current = true
    }
  }, [])

  //change active path when manually or externally changed
  useEffect(() => {
    if (!manuallyRouteChanged.current) {
      let looseMatch = false
      const newPath = location.pathname

      //exact match
      const index = items.findIndex(item => newPath === item.url)

      if (index === -1) {
        //loose match logic
        const looseIndex = items.findIndex(item => newPath.includes(item.url))

        if (looseIndex !== -1) looseMatch = true
        changeRoute(looseIndex, looseMatch)
        return
      }
      changeRoute(index)
    } else {
      manuallyRouteChanged.current = false
    }
  }, [location, items, changeRoute])

  const handleItemClick = index => {
    sessionStorage.setItem(SELECTED_NAVBAR_INDEX, index)
    if (isDropdown) toggleDropdown()
    routeTo(index)
  }

  const routeTo = index => {
    setSelectedItemIndex(index)
    if (index === -1) return

    const regionId = localStorage.getItem('region_id')
    let appendRoute = ''
    const selectedItemKey = items[index]?.slug
    if (![MENU_ITEMS.myAccount, MENU_ITEMS.login].includes(selectedItemKey)) {
      appendRoute = `/r/${regionId}`

      if (
        Object.keys(centers)?.length === 1 &&
        selectedItemKey === MENU_ITEMS.pricing
      ) {
        appendRoute += `/loc/${Object.keys(centers)?.[0]}`
      }
    }
    history.push(items[index]?.url + appendRoute + location.search)
    manuallyRouteChanged.current = true
  }

  return {
    handleItemClick,
    selectedItemIndex,
    isOpenDropdown,
    toggleDropdown,
    isLoggedIn,
    commonMenuItems,
    loginMenuItem
  }
}

export default useNavbar
