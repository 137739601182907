import { inIframe } from 'Utils/helper'
import React from 'react'
import styled from 'styled-components'
import ErrorBoundaryIcon from 'Components/Icons/ErrorBoundary'
import Button from 'Components/Button'

const ErrorBoundaryWrapper = styled.div`
  min-height: 40vh;
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 1rem;
  max-height: 70vh;
  color: ${({ theme }) => theme.color.secondaryText};
  & > svg {
    margin: 32px 0;
  }
  & > p {
    margin-bottom: 8px;
    text-align: center;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    max-height: 60vh;
  }
`

const Header = styled.h3`
  font-size: 30px;
  font-weight: bold;
  color: black;
  margin-bottom: 32px;
`

const SubText = styled.p`
  font-size: 20px;
  color: #546a7b;
  padding-bottom: 60px;
  max-width: 540px;
`

const ErrorBoundaryPage = ({ error }) => {
  const triggerReload = () => {
    parent.location.reload()
    if (inIframe()) {
      window.parent.postMessage(
        JSON.stringify({
          type: 'TriggerReload',
          message: null
        }),
        localStorage.getItem('parentOrigin')
      )
    }
  }

  return (
    <ErrorBoundaryWrapper>
      <ErrorBoundaryIcon />
      <Header>Uh oh! Something went wrong</Header>
      <SubText>
        Don’t worry, it’s not your fault. Please refresh the page. If the issue
        persists, please reach out to the business.
      </SubText>
      <Button onClick={() => triggerReload()}>Refresh page</Button>
    </ErrorBoundaryWrapper>
  )
}

export default ErrorBoundaryPage
