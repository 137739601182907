import {
  FETCHING_VIDEOS,
  VOD_VIDEO,
  VOD_VIDEOS,
  VOD_VIDEO_ERROR,
  VOD_VIDEOS_ERROR,
  VOD_PLAYLISTS,
  VOD_PLAYLISTS_ERROR,
  VOD_PLAYLIST_DETAILS,
  VOD_PLAYLIST_DETAILS_ERROR,
  VOD_RENTED_VIDEOS,
  VOD_RENTED_PLAYLISTS,
  FETCHING_RENTED_VIDEOS,
  VOD_CATEGORIES
} from 'Constants'

const INITIAL_STATE = {
  playlists: [],
  videos: [],
  selected_playlist: {},
  selected_video: {},
  video_details: null,
  playlist_details: null,
  error_playlists: '',
  error_video: false,
  error: '',
  isFetchingVideos: true,
  isFetchingRentedVideos: false,
  rentedVideos: {},
  rentedPlaylists: {},
  categories: []
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCHING_VIDEOS:
      return { ...state, isFetchingVideos: true }
    case VOD_VIDEO:
      return { ...state, video_details: action.payload }
    case VOD_VIDEO_ERROR:
      return { ...state, error_video: true }
    case VOD_VIDEOS:
      return { ...state, videos: action.payload, isFetchingVideos: false }
    case VOD_VIDEOS_ERROR:
      return { ...state, isFetchingVideos: false }
    case VOD_PLAYLISTS:
      return {
        ...state,
        playlists: action.payload.filter(
          pl => pl.videos.length > 0 && pl.is_published == 1
        )
      }
    case FETCHING_RENTED_VIDEOS:
      return {
        ...state,
        isFetchingRentedVideos: true
      }
    case VOD_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      }
    case VOD_RENTED_PLAYLISTS:
      return {
        ...state,
        rentedPlaylists: action.payload
      }
    case VOD_RENTED_VIDEOS:
      return {
        ...state,
        rentedVideos: action.payload
      }
    case VOD_PLAYLISTS_ERROR:
      return { ...state, error: action.payload }

    case VOD_PLAYLIST_DETAILS:
      // let mapedVideos = action.payload.videos.map((v, i)=> {return ({...v, order: i+1})})
      return { ...state, playlist_details: action.payload }
    // return { ...state, playlist_details: {...action.payload, videos: mapedVideos }};
    case VOD_PLAYLIST_DETAILS_ERROR:
      return { ...state, error_playlists: action.payload }

    case 'VOD_CLEAR':
      return INITIAL_STATE

    case 'SET_SELECTED':
      return {
        ...state,
        selected_playlist: action.payload.playlits,
        selected_video: action.payload.video
      }

    default:
      return state
  }
}
