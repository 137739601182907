import React, { useEffect } from 'react'
import styled from 'styled-components'
import Account from '../Icons/Account'
import useNavbar from './useNavbar'
import { useSelector } from 'react-redux'
const Menu = styled.div`
  position: relative;
  display: flex;
  padding: 24px 0;
  justify-content: space-between;
`

const Section = styled.div`
  display: flex;
  gap: 32px;
  justify-content: space-between;
`

const MenuItem = styled.a`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: ${props => props.theme.color.primaryText};
  cursor: pointer;
  text-decoration: none;
  &:hover,
  &.active {
    color: ${props => props.theme.color.brand};
  }
`

const Dweb = ({ items }) => {
  const {
    selectedItemIndex,
    isLoggedIn,
    commonMenuItems,
    loginMenuItem
  } = useNavbar(items)

  const renderOption = ({ index, children, slug, url }) => (
    <MenuItem
      key={slug}
      href={url}
      className={index === selectedItemIndex ? 'active' : ''}
    >
      {children}
    </MenuItem>
  )
  const customerProfile = useSelector(
    state => state.accountreducer.customerProfile
  ) || { data: JSON.parse(localStorage.getItem('contact')) }

  return (
    <Menu>
      <Section>
        {commonMenuItems.map(({ index, title, slug, url }) =>
          renderOption({
            index,
            children: <>{title}</>,
            slug,
            url
          })
        )}
      </Section>
      <Section>
        {renderOption({
          index: loginMenuItem?.index,
          children: (
            <>
              {isLoggedIn ? (
                <>
                  <Account />
                  <span>{customerProfile?.data?.first_name}</span>
                </>
              ) : (
                <>{loginMenuItem?.title}</>
              )}
            </>
          ),
          slug: loginMenuItem?.slug,
          url: loginMenuItem?.url
        })}
      </Section>
    </Menu>
  )
}

export default Dweb
