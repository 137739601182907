import React from 'react'
import { useTheme } from 'styled-components'

function Icon() {
  const theme = useTheme() ?? { color: { brand: '#3be7ef' } }

  return (
    <svg dwidth="888" height="677.20705" viewBox="0 0 888 677.20705">
      <polygon
        points="307.693 659.535 316.417 662.148 330.648 629.74 317.772 625.884 307.693 659.535"
        fill="#ffb8b8"
      />

      <path
        d="M462.32048,767.41626l17.18156,5.1462.00069.00021a11.43063,11.43063,0,0,1,7.66935,14.22912l-.10659.35581-28.13113-8.4259Z"
        transform="translate(-156 -111.39648)"
        fill="#2f2e41"
      />

      <polygon
        points="390.602 666.663 399.153 663.528 391.13 629.055 378.51 633.682 390.602 666.663"
        fill="#ffb8b8"
      />
      <path
        d="M543.39769,776.06729l16.83948-6.17423.00068-.00025a11.43063,11.43063,0,0,1,14.6658,6.79715l.12785.34873L547.46038,787.1476Z"
        transform="translate(-156 -111.39648)"
        fill="#2f2e41"
      />
      <polygon
        points="322.321 600.868 307.464 649.897 325.292 655.097 342.378 609.782 322.321 600.868"
        fill="#2f2e41"
      />
      <polygon
        points="369.121 612.011 382.493 658.068 401.064 649.154 387.693 606.068 369.121 612.011"
        fill="#2f2e41"
      />
      <path
        d="M518.16217,731.71429a203.9796,203.9796,0,0,1-35.45752-3.27034l-.298-.06021v-11.3301l-6.78986-.75447,5.29979-18.92774c-2.47016-29.16024,1.03993-66.08934,2.17563-76.71865.26025-2.49265.43146-3.89132.43146-3.89132l5.95867-50.64955,9.97024-9.20308,4.52936,2.94751,8.40181,8.40145c9.766,24.0342,17.51548,46.661,17.56426,48.11483l32.75141,104.95321-.2209.156C551.10618,729.50964,533.77044,731.71429,518.16217,731.71429Z"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <polygon
        points="337.708 477.181 335.418 491.475 350.921 497.87 337.708 477.181"
        opacity="0.2"
      />
      <path
        d="M524.12819,556.834H488.28426a2.78413,2.78413,0,0,1-2.781-2.781v-15.45a20.703,20.703,0,0,1,41.40592,0v15.45A2.78412,2.78412,0,0,1,524.12819,556.834Z"
        transform="translate(-156 -111.39648)"
        fill="#2f2e41"
      />
      <circle cx="354.16363" cy="429.32816" r="15.17868" fill="#ffb8b8" />
      <path
        d="M531.807,540.148H509.88932l-.22481-3.14673-1.12374,3.14673h-3.37485l-.4454-6.23672-2.22727,6.23672h-6.53018v-.309a16.39537,16.39537,0,0,1,16.37682-16.377H515.43a16.39549,16.39549,0,0,1,16.377,16.377Z"
        transform="translate(-156 -111.39648)"
        fill="#2f2e41"
      />
      <path
        d="M509.70994,559.718a2.84058,2.84058,0,0,1-.49216-.04345l-16.04911-2.83169V530.31841h17.667l-.4374.51c-6.08554,7.09733-1.50079,18.60574,1.77373,24.834a2.73982,2.73982,0,0,1-.21772,2.90894A2.76982,2.76982,0,0,1,509.70994,559.718Z"
        transform="translate(-156 -111.39648)"
        fill="#2f2e41"
      />
      <path
        d="M538.02851,623.82967h-8.79718a1.1313,1.1313,0,0,1-1.13-1.02492l-1.7609-18.04893h14.579l-1.7609,18.04871A1.13131,1.13131,0,0,1,538.02851,623.82967Z"
        transform="translate(-156 -111.39648)"
        fill={theme.color.brand}
      />
      <path
        d="M540.89615,607.02652H526.36369a1.13668,1.13668,0,0,1-1.13534-1.13535v-2.72484a1.13668,1.13668,0,0,1,1.13534-1.13534h14.53246a1.13668,1.13668,0,0,1,1.13535,1.13534v2.72484A1.13669,1.13669,0,0,1,540.89615,607.02652Z"
        transform="translate(-156 -111.39648)"
        fill="#2f2e41"
      />
      <path
        d="M483.8924,616.06358l0,0a27.88129,27.88129,0,0,0,33.46807,6.76478l3.30359-1.635Z"
        transform="translate(-156 -111.39648)"
        opacity="0.2"
      />
      <path
        d="M536.71057,612.5695a6.96579,6.96579,0,0,0-10.67635.32242l-15.32558-4.30242-4.88606,8.67563,21.728,5.77187a7.00355,7.00355,0,0,0,9.16-10.4675Z"
        transform="translate(-156 -111.39648)"
        fill="#ffb8b8"
      />
      <path
        d="M500.13852,622.19727c-7.28644.00072-17.14532-4.27146-28.84884-12.546a5.73089,5.73089,0,0,1-2.4134-3.92759c-.86328-5.46918,4.4715-12.863,4.99455-13.57174l5.60811-15.40061c.06456-.25028,1.87184-6.91355,6.409-9.28432a7.43841,7.43841,0,0,1,6.21386-.26479c8.6423,3.147,1.894,27.44817.96757,30.63508l11.45016,5.38938,7.27119,4.6349,9.95773,1.04175-2.70358,12.51186-15.12275.34024A15.43364,15.43364,0,0,1,500.13852,622.19727Z"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <path
        d="M1043.67468,508.05561A98.57951,98.57951,0,1,0,854.454,546.865c-.09637-.10718-.196-.21149-.29193-.31909a98.66626,98.66626,0,0,0,17.95428,27.78283c.0224.02466.04541.04871.06788.07337.60559.66,1.21466,1.31659,1.83782,1.9599a98.28006,98.28006,0,0,0,69.52936,30.25354l-3.33105,180.9292h10.29126l-2.08283-119.41541,14.88678-7.83758-2.27093-4.31354-12.711,6.692-.97791-56.06378A98.57812,98.57812,0,0,0,1043.67468,508.05561Z"
        transform="translate(-156 -111.39648)"
        fill="#e6e6e6"
      />
      <path
        d="M751.0871,460.00819a115.52648,115.52648,0,1,0-221.74982,45.4812c-.113-.12561-.22968-.24784-.3421-.37393a115.62807,115.62807,0,0,0,21.04083,32.559c.02625.02893.05322.05706.07959.086.70972.7735,1.42346,1.543,2.15375,2.29682a115.17561,115.17561,0,0,0,81.48224,35.45447l-3.90375,212.033h12.06042l-2.44091-139.94428,17.446-9.185-2.66138-5.05505-14.89618,7.84247-1.146-65.70178A115.52494,115.52494,0,0,0,751.0871,460.00819Z"
        transform="translate(-156 -111.39648)"
        fill="#e6e6e6"
      />
      <path
        d="M419.25891,414.8147A131.46683,131.46683,0,1,0,166.912,466.57142c-.12855-.14295-.26138-.282-.38931-.42551a131.58166,131.58166,0,0,0,23.944,37.05149c.02989.03289.06059.065.09055.09787.80763.88021,1.61988,1.75582,2.45091,2.61373a131.0676,131.0676,0,0,0,92.72516,40.3465L281.291,787.54475h13.72454L292.23788,628.291,312.091,617.83869l-3.02856-5.75256-16.95154,8.92456-1.30411-74.76727A131.465,131.465,0,0,0,419.25891,414.8147Z"
        transform="translate(-156 -111.39648)"
        fill="#e6e6e6"
      />
      <circle cx="756.68549" cy="85.97574" r="85.97575" fill="#ff6584" />
      <circle
        cx="245.55884"
        cy="187.61628"
        r="172.3117"
        fill={theme.color.brand}
      />
      <polygon
        points="246.032 187.616 246.506 187.616 255.027 676.148 237.038 676.148 246.032 187.616"
        fill="#3f3d56"
      />
      <rect
        x="401.08538"
        y="564.58106"
        width="32.19013"
        height="8.5209"
        transform="translate(-372.96557 148.44822) rotate(-27.76587)"
        fill="#3f3d56"
      />
      <path
        d="M665.11523,782.975s.62171-13.02673,13.3664-11.51257"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <circle
        cx="505.51413"
        cy="652.80328"
        r="6.37865"
        fill={theme.color.brand}
      />
      <rect
        x="504.47623"
        y="663.54456"
        width="1.80054"
        height="12.60376"
        fill="#3f3d56"
      />
      <path
        d="M223.08289,781.17445s.62168-13.02674,13.36641-11.51258"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <circle
        cx="63.48181"
        cy="651.00275"
        r="6.37865"
        fill={theme.color.brand}
      />
      <rect
        x="62.44391"
        y="661.74402"
        width="1.80054"
        height="12.60376"
        fill="#3f3d56"
      />
      <path
        d="M327.51416,782.07471s.6217-13.02673,13.36642-11.51257"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <circle
        cx="167.91309"
        cy="651.90302"
        r="6.37865"
        fill={theme.color.brand}
      />
      <rect
        x="166.87518"
        y="662.64429"
        width="1.80054"
        height="12.60376"
        fill="#3f3d56"
      />
      <path
        d="M605.24329,194.695l12.79486-10.23341c-9.93976-1.09662-14.02381,4.3243-15.69525,8.615-7.76532-3.22446-16.21882,1.00135-16.21882,1.00135l25.6001,9.29375A19.37211,19.37211,0,0,0,605.24329,194.695Z"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <path
        d="M799.827,298.93629l12.7948-10.23343c-9.93976-1.09662-14.02381,4.32431-15.69526,8.615-7.76532-3.22446-16.21881,1.00134-16.21881,1.00134l25.6001,9.29376A19.37241,19.37241,0,0,0,799.827,298.93629Z"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <path
        d="M589.95459,387.88883l12.7948-10.23343c-9.93976-1.09662-14.0238,4.32431-15.69525,8.615-7.76532-3.22445-16.21881,1.00138-16.21881,1.00138l25.60009,9.29373A19.37244,19.37244,0,0,0,589.95459,387.88883Z"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <path
        d="M839.65527,787.70325s.62171-13.02673,13.3664-11.51257"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <path
        d="M719.91943,787.70325s.62171-13.02673,13.3664-11.51257"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <path
        d="M283.2887,787.70325s.6217-13.02673,13.36642-11.51257"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <path
        d="M893.67145,787.70325s.6217-13.02673,13.36639-11.51257"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <path
        d="M868.46387,787.70325s.6217-13.02673,13.36639-11.51257"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <path
        d="M816.465,787.70325s-.62171-13.02673-13.3664-11.51257"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <path
        d="M609.40308,787.70325s-.62171-13.02673-13.3664-11.51257"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <path
        d="M437.4516,787.70325s-.6217-13.02673-13.36643-11.51257"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <path
        d="M254.69688,787.70325s-.6217-13.02673-13.36641-11.51257"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <path
        d="M947.90442,787.70325s-.62171-13.02673-13.3664-11.51257"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <path
        d="M870.4812,788.60352s-.6217-13.02673-13.36639-11.51257"
        transform="translate(-156 -111.39648)"
        fill="#3f3d56"
      />
      <rect y="674.60352" width="888" height="2" fill="#3f3d56" />
    </svg>
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="586.479"
    //   height="659.298"
    //   viewBox="0 0 586.479 659.298"
    // >
    //   <circle cx="332.479" cy="254" r="254" fill="#f2f2f2"></circle>
    //   <path
    //     fill="#cacaca"
    //     d="M498.464 113.588H33.17a1.807 1.807 0 010-3.613h465.293a1.807 1.807 0 010 3.613z"
    //   ></path>
    //   <rect
    //     width="163.611"
    //     height="34.986"
    //     x="193.774"
    //     y="174.473"
    //     fill="#fff"
    //     rx="17.493"
    //     ry="17.493"
    //   ></rect>
    //   <path
    //     fill="#fff"
    //     d="M128.175 244.445h294.81c9.662 0 17.494 7.832 17.494 17.494 0 9.66-7.832 17.493-17.494 17.493h-294.81c-9.661 0-17.493-7.832-17.493-17.493 0-9.662 7.832-17.494 17.493-17.494zM128.175 314.418h294.81c9.662 0 17.494 7.832 17.494 17.493 0 9.662-7.832 17.493-17.494 17.493h-294.81c-9.661 0-17.493-7.831-17.493-17.493 0-9.66 7.832-17.493 17.493-17.493z"
    //   ></path>
    //   <path
    //     fill="#f2f2f2"
    //     d="M91.64 657.76l-.693-.069a80.529 80.529 0 01-58.188-34.618 78.131 78.131 0 01-8.815-17.504l-.211-.59.624-.05c7.447-.6 15.097-1.863 18.495-2.464l-21.914-7.425-.136-.65a15.272 15.272 0 016.429-15.863 15.534 15.534 0 0117.404-.294c2.386 1.524 4.881 3.033 7.295 4.494 8.293 5.016 16.868 10.203 23.298 17.3a48.675 48.675 0 0111.63 40.256l4.783 17.476z"
    //   ></path>
    //   <path
    //     fill={theme.color.brand}
    //     d="M171.30016 646.86102L182.10017 646.85999 187.23916 605.198 171.29716 605.19897 171.30016 646.86102z"
    //   ></path>
    //   <path
    //     fill="#2f2e41"
    //     d="M170.92 658.128l33.214-.001v-.42c0-7.14-5.789-12.927-12.928-12.928h-.001l-6.067-4.603-11.32 4.604h-2.9l.001 13.348z"
    //   ></path>
    //   <path
    //     fill="#a0616a"
    //     d="M84.74116 616.94501L93.38016 623.42603 122.49316 593.185 109.74116 583.61902 84.74116 616.94501z"
    //   ></path>
    //   <path
    //     fill="#2f2e41"
    //     d="M77.674 625.73l26.57 19.932.252-.336c4.284-5.712 3.128-13.815-2.583-18.1l-2.092-7.323-11.817-3.111-2.32-1.74-8.01 10.678zM120.645 451.353s.596 16.264 1.348 29.307c.123 2.14-4.888 4.463-4.758 6.79.086 1.544 1.028 3.045 1.115 4.655.092 1.699-1.208 3.203-1.115 4.96.09 1.716 1.574 3.685 1.663 5.442.963 18.91 4.455 41.547.959 47.875-1.723 3.118-23.686 46.324-23.686 46.324s12.237 18.355 15.733 12.237c4.618-8.081 40.206-45.888 40.206-53.107 0-7.211 8.234-61.254 8.234-61.254l5.74 31.982 2.633 6.337-.827 3.72 1.701 5.02.092 4.968 1.656 9.226s-4.982 71.882-2.176 73.884c2.814 2.01 16.44 5.62 18.049 2.01 1.6-3.61 12.01-75.02 12.01-75.02s1.679-32.724 3.497-63.14c.105-1.766 1.346-3.899 1.442-5.638.114-2.02-.673-4.641-.568-6.6.114-2.246 1.11-3.828 1.206-5.978.743-16.615-3.427-36.849-4.756-38.842a265.865 265.865 0 00-7.622-10.82s-54.035-17.754-68.475.286l-3.301 25.376z"
    //   ></path>
    //   <path
    //     fill="#3f3d56"
    //     d="M174.538 284.104l-21.421-4.284-9.997 13.566c-18.652 18.34-18.933 34.528-15.603 60.474v36.416l-2.41 24.411s-8.532 17.846.268 22 66.598 3.801 72.117 2.143.735-3.695-.714-11.425c-2.723-14.52-.902-7.516-.714-12.138 2.685-66.314-3.57-93.538-4.206-100.694l-10.894-19.759-6.426-10.71z"
    //   ></path>
    //   <path
    //     fill="#a0616a"
    //     d="M287.44 337.571a8.66 8.66 0 01-12.985 2.788l-46.26 29.373.133-15.992 44.939-26.079a8.707 8.707 0 0114.172 9.91z"
    //   ></path>
    //   <path
    //     fill="#3f3d56"
    //     d="M157.625 302.624l-5.267-.558a13.936 13.936 0 00-12.58 5.491 13.58 13.58 0 00-2.374 4.992l-.003.014a13.922 13.922 0 005.258 14.481l18.19 13.411c12.765 17.25 36.757 28.693 64.898 37.99l43.743-27.166-15.472-18.739-30.003 16.08-44.598-34.524-.026-.02-16.974-10.937-4.792-.515z"
    //   ></path>
    //   <circle cx="167.3" cy="248.605" r="24.98" fill="#a0616a"></circle>
    //   <path
    //     fill="#2f2e41"
    //     d="M167.877 273.59c-.201.007-.403.012-.605.017-.086.224-.18.446-.287.664l.892-.68zM174.732 249.298c.04.246.1.489.18.725a3.409 3.409 0 00-.18-.725z"
    //   ></path>
    //   <path
    //     fill="#2f2e41"
    //     d="M192.599 224.694c-1.029 3.193-1.946-.857-5.329-.128-4.069.876-8.803.575-12.136-1.92a18.801 18.801 0 00-17.223-2.624c-5.701 2.017-15.253 3.439-16.739 9.301-.516 2.038-.721 4.25-1.973 5.939-1.095 1.476-2.822 2.318-4.266 3.455-4.878 3.837-1.15 14.74 1.159 20.5 2.308 5.76 7.603 9.957 13.425 12.107 5.633 2.08 11.746 2.446 17.755 2.283 1.045-2.71.594-5.841-.269-8.651-.933-3.042-2.316-5.978-2.706-9.136s.467-6.746 3.115-8.51c2.434-1.622 6.395-.634 7.32 1.988-.54-3.278 2.781-6.45 6.206-7.009 3.676-.599 7.352.729 11.018 1.39s2.365-14.774.643-18.985z"
    //   ></path>
    //   <circle
    //     cx="281.358"
    //     cy="285.711"
    //     r="51.12"
    //     fill={theme.color.brand}
    //     transform="rotate(-85.269 281.359 285.71)"
    //   ></circle>
    //   <path
    //     fill="#fff"
    //     d="M294.787 264.41l-13.429 13.429-13.428-13.428a5.566 5.566 0 10-7.872 7.871l13.429 13.429-13.429 13.428a5.566 5.566 0 007.872 7.872l13.428-13.429 13.429 13.429a5.566 5.566 0 007.872-7.872L289.23 285.71l13.429-13.429a5.566 5.566 0 00-7.872-7.871z"
    //   ></path>
    //   <path
    //     fill="#a0616a"
    //     d="M261.214 242.744a8.66 8.66 0 01-6.977 11.3l-10.83 53.716-11.448-11.168 12.292-50.482a8.707 8.707 0 0116.963-3.366z"
    //   ></path>
    //   <path
    //     fill="#3f3d56"
    //     d="M146.125 312.225l-4.049 3.414a13.935 13.935 0 00-4.746 12.879 13.58 13.58 0 001.958 5.17l.008.012a13.923 13.923 0 0014.09 6.23l22.27-3.841c21.285 2.73 46.152-6.66 72.344-20.53l10.68-50.373-24.234-1.808-9.168 32.782-55.788 8.282-.033.004-19.643 4.678-3.689 3.1z"
    //   ></path>
    //   <path
    //     fill="#cacaca"
    //     d="M272.937 658.99l-271.75.308a1.19 1.19 0 010-2.382l271.75-.307a1.19 1.19 0 010 2.381z"
    //   ></path>
    //   <g fill="#3f3d56">
    //     <ellipse cx="56.777" cy="82.058" rx="8.457" ry="8.645"></ellipse>
    //     <ellipse cx="85.991" cy="82.058" rx="8.457" ry="8.645"></ellipse>
    //     <ellipse cx="115.204" cy="82.058" rx="8.457" ry="8.645"></ellipse>
    //     <path d="M148.516 88.891c-.26 0-.52-.1-.715-.3l-5.706-5.834a1 1 0 010-1.398l5.706-5.833a1.001 1.001 0 011.43 1.399l-5.022 5.133 5.022 5.134a1 1 0 01-.715 1.7zM158.104 88.891a1 1 0 01-.715-1.7l5.022-5.133-5.022-5.133a1 1 0 111.43-1.399l5.706 5.833a1 1 0 010 1.398l-5.706 5.833c-.196.2-.455.301-.715.301zM456.614 74.414h-10.61c-1.21 0-2.19.98-2.19 2.19v10.62c0 1.21.98 2.19 2.19 2.19h10.61c1.21 0 2.2-.98 2.2-2.19v-10.62c0-1.21-.99-2.19-2.2-2.19zM430.614 74.414h-10.61c-1.21 0-2.19.98-2.19 2.19v10.62c0 1.21.98 2.19 2.19 2.19h10.61c1.21 0 2.2-.98 2.2-2.19v-10.62c0-1.21-.99-2.19-2.2-2.19zM481.114 74.914h-10.61c-1.21 0-2.19.98-2.19 2.19v10.62c0 1.21.98 2.19 2.19 2.19h10.61c1.21 0 2.2-.98 2.2-2.19v-10.62c0-1.21-.99-2.19-2.2-2.19zM321.192 78.954h-84.81a2.67 2.67 0 100 5.34h84.81c1.47 0 2.67-1.2 2.67-2.67s-1.2-2.67-2.67-2.67z"></path>
    //   </g>
    // </svg>
  )
}

export default Icon
