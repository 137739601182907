import {
  FETCHING_WAIVER,
  SIGN_WAIVER,
  WAIVER_DETAILS,
  GET_IMAGE,
  WAIVER_ERROR
} from 'Constants'

const INITIAL_STATE = {
  isFetchingWaiver: false,
  waiver: null,
  signData: null,
  signUrl: null,
  isSigning: false
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCHING_WAIVER:
      return { ...state, isFetchingWaiver: true }
    case SIGN_WAIVER:
      return { ...state, signData: action.payload, isSigning: true }
    case WAIVER_DETAILS:
      return { ...state, waiver: action.payload, isFetchingWaiver: false }
    case GET_IMAGE:
      return { ...state, signUrl: action.payload }
    case WAIVER_ERROR:
      return { ...state, isFetchingWaiver: false, waiver: action.payload }
    default:
      return state
  }
}
