import { SAVEDRELATEDCONTACT, CLEARSAVEDRELATEDCONTACT } from 'Constants'

export default function(state = null, action) {
  switch (action.type) {
    case SAVEDRELATEDCONTACT:
      return { ...action.payload }
    case CLEARSAVEDRELATEDCONTACT: {
      return null
    }
    default:
      return state
  }
}
