import { SAVEDEMERGENCYCONTACT, CLEARSAVEDEMERGENCYCONTACT } from 'Constants'

export default function(state = null, action) {
  switch (action.type) {
    case SAVEDEMERGENCYCONTACT:
      return { ...action.payload }
    case CLEARSAVEDEMERGENCYCONTACT: {
      return null
    }
    default:
      return state
  }
}
