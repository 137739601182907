import {
  GIFT,
  GDETAILS,
  CLEARGIFT,
  GIFT_DETAILS,
  ALL_GIFT_CARDS
} from 'Constants'

const INITIAL_STATE = {
  gifts: null,
  gDetails: null,
  giftDetails: null
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GIFT:
      return { ...state, gifts: action.cards }
    case CLEARGIFT:
      return { ...state, giftDetails: null }
    case GDETAILS:
      return { ...state, gDetails: action.payload }
    case GIFT_DETAILS:
      return { ...state, giftDetails: action.payload }
    default:
      return state
  }
}
