import {
  GET_FIELDS,
  GET_FIELDS_ERROR,
  SUBMIT_FORM,
  SUBMIT_FORM_ERROR,
  FETCHING_CUSTOM_FORM
} from 'Constants'

const INITIAL_STATE = {
  formFields: [],
  isFetchingFields: false,
  isSubmittingForm: false,
  formStatus: null
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCHING_CUSTOM_FORM:
      return { ...state, isFetchingFields: true }
    case GET_FIELDS:
      return {
        ...state,
        isFetchingFields: false,
        formFields: action.payload,
        formStatus: null
      }
    case GET_FIELDS_ERROR:
      return {
        ...state,
        isFetchingFields: false,
        formStatus: action.payload,
        formFields: []
      }
    case SUBMIT_FORM:
      return { ...state, isSubmittingForm: true, formStatus: action.payload }
    case SUBMIT_FORM_ERROR:
      return { ...state, isSubmittingForm: true, formStatus: action.payload }
    default:
      return state
  }
}
