import { RENDER_SERVICE_PAGE } from 'Constants'

const INITIAL_STATE = {
  renderServicePage: false
}

export default function(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case RENDER_SERVICE_PAGE:
      return {
        ...state,
        renderServicePage: payload
      }

    default:
      return state
  }
}
