import { client } from './client'
const axios = client()
import {
  EVENTS,
  FETCHING_EVENTS,
  TRAINERS,
  EVENTDETAILS,
  NEXTEVENTDATE,
  BOOK,
  CLEARSTATUS,
  CLASSES,
  ROOMS,
  SETFROMEVENT,
  CLEARFROMEVENT
} from 'Constants'
import { API_ROOT } from 'Config'
import { store } from '../store.index'
import { trackEvent } from '../utils/helper'
import { bookee_booked_class, eventCategoryOptions } from '../constants/events'

let getAxiosConfig = () => {
  return {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('customerkey')
    }
  }
}

export function setFromEvent(uri, eventData, isAppointment = false) {
  return dispatch =>
    dispatch({
      type: SETFROMEVENT,
      payload: { uri, eventData, isAppointment }
    })
}
export function clearFromEvent() {
  return dispatch =>
    dispatch({
      type: CLEARFROMEVENT
    })
}
export function clearStatus() {
  return dispatch =>
    dispatch({
      type: CLEARSTATUS,
      payload: ''
    })
}

export function startFetchingEvents(timestamp) {
  return {
    type: FETCHING_EVENTS,
    timestamp
  }
}

export function getEvents(postData, timestamp) {
  if (
    !localStorage.getItem('region_id') ||
    !localStorage.getItem('url_prefix')
  ) {
    return dispatch => {
      dispatch({ type: EVENTS, payload: '', timestamp })
    }
  }
  let axiosConfig = getAxiosConfig()
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }
  let requestUrl = ''
  if (postData.filters.created_at !== undefined) {
    requestUrl = `${API_ROOT}/api/embed/events?date=${postData.filters.created_at}`
  } else {
    requestUrl = `${API_ROOT}/api/embed/events`
  }
  let request = axios.post(requestUrl, postData, axiosConfig)

  return dispatch => {
    request
      .then(({ data }) => {
        dispatch({ type: EVENTS, payload: data.data.events, timestamp })
        dispatch({ type: NEXTEVENTDATE, payload: data.data.next_event_date })
      })
      .catch(err => {
        dispatch({ type: EVENTS, payload: err, timestamp })
        dispatch({ type: NEXTEVENTDATE, payload: err })
      })
  }
}

export function getEventsDetails(postData) {
  let axiosConfig = getAxiosConfig()
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }

  let requestUrl = `${API_ROOT}/api/embed/events/${postData.event_id}`
  let request = axios.get(requestUrl, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        dispatch({ type: EVENTDETAILS, payload: data.data.event })
      })
      .catch(err => {
        dispatch({ type: EVENTDETAILS, payload: err })
      })
  }
}

export const getEventEligibility = postData => {
  const axiosConfig = getAxiosConfig()
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    center_id:
      localStorage.getItem('selected_center_id') ||
      localStorage.getItem('center_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }

  const requestUrl = `${API_ROOT}/api/embed/prerequisites/eligibility`
  return axios.post(requestUrl, postData, axiosConfig)
}

export const confirmEventWaitlistSlot = eventId => {
  const axiosConfig = getAxiosConfig()
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    center_id:
      localStorage.getItem('selected_center_id') ||
      localStorage.getItem('center_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }

  const requestUrl = `${API_ROOT}/api/embed/events/${eventId}/confirm`
  return axios.get(requestUrl, axiosConfig)
}

export const getBookingRulesEligibility = postData => {
  const axiosConfig = getAxiosConfig()
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    center_id:
      localStorage.getItem('selected_center_id') ||
      localStorage.getItem('center_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }

  const requestUrl = `${API_ROOT}/api/embed/booking_rules/eligibility`
  return axios.post(requestUrl, postData, axiosConfig)
}

export function getTrainers() {
  let axiosConfig = getAxiosConfig()
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    service_id: '3',
    url_prefix: store.getState().accountreducer.urlPrefix
  }

  let requestUrl = `${API_ROOT}/api/embed/trainers`
  let request = axios.get(requestUrl, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        // Sort trainers by first name
        data.data.users.sort((a, b) => {
          let nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })

        dispatch({ type: TRAINERS, payload: data.data.users })
      })
      .catch(err => {
        dispatch({ type: TRAINERS, payload: err })
      })
  }
}
export function getRooms() {
  let axiosConfig = getAxiosConfig()
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }

  let requestUrl = `${API_ROOT}/api/embed/facilities`
  let request = axios.get(requestUrl, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        dispatch({ type: ROOMS, payload: data.data.facilities })
      })
      .catch(err => {
        dispatch({ type: ROOMS, payload: err })
      })
  }
}
export function getClasses() {
  let axiosConfig = getAxiosConfig()
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }

  let requestUrl = `${API_ROOT}/api/embed/classes`
  let request = axios.get(requestUrl, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        dispatch({ type: CLASSES, payload: data.data.classes })
      })
      .catch(err => {
        dispatch({ type: CLASSES, payload: err })
      })
  }
}

export function bookEvent(postData) {
  let axiosConfig = getAxiosConfig()

  let requestUrl = ''

  let reqBody = {
    center_contact_id: postData.center_id,
    event_id: postData.event_id,
    payment: {
      amount: postData.free ? 0 : postData.event_id,
      payment_method: postData.free ? 'cash' : 'credit',
      payment_mode: 'offline',
      credit_id: postData.credit_id
    },
    url_prefix: store.getState().accountreducer.urlPrefix
  }
  if (postData.book_for) {
    reqBody.book_for = postData.book_for
  }
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }
  requestUrl = `${API_ROOT}/api/embed/events/${postData.event_id}/book`
  let request = axios.post(requestUrl, reqBody, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        dispatch({ type: BOOK, payload: data })

        trackEvent(
          {
            category: eventCategoryOptions.actionName,
            action: 'Books a class',
            label: bookee_booked_class
          },
          { name: postData.name }
        )
      })
      .catch(err => {
        dispatch({ type: BOOK, payload: err.response?.data })
      })
  }
}
