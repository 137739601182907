import {
  SAVEDCARD,
  CLEARSAVEDCARD,
  ADYENCONFIG,
  ADYENCONFIG_ERROR,
  SAVE_ADYEN_SESSION
} from 'Constants'

const initialState = {
  saveCardSubmitStatus: null,
  adyenConfig: null,
  adyenSession: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ADYENCONFIG:
      return { ...state, adyenConfig: action.payload }
    case SAVE_ADYEN_SESSION:
      return { ...state, adyenSession: action.payload }
    case ADYENCONFIG_ERROR:
      return { ...state, adyenConfig: null }
    case SAVEDCARD:
      return { ...state, saveCardSubmitStatus: action.payload }
    case CLEARSAVEDCARD: {
      return { ...state, saveCardSubmitStatus: null }
    }
    default:
      return state
  }
}
