import {
  LOGIN,
  LOGOUT,
  SIGNUP,
  RESET,
  VERIFY,
  CONFIRM,
  GENERATE,
  PAST_BOOKING_DETAILS,
  UPCOMING_BOOKING_DETAILS,
  CUSTOMERPROFILEDETAILS,
  PAYMENTDETAILS,
  RELATEDCONTACTSLIST,
  EMERGENCYCONTACTSLIST,
  MEMBERSHIP,
  SAVEDCARDS,
  SETURLPREFIX,
  PAYMENTMODES,
  ALL_GIFT_CARDS,
  GET_PROFILE_IMAGE,
  PROFILE_IMAGE_ERROR,
  UPDATE_USER_PROFILE
} from 'Constants'
import {
  INSTALLMENTS,
  PAYMENT_METHOD_TYPES,
  SAVED_PAYMENT_METHODS
} from '../constants'

const INITIAL_STATE = {
  urlPrefix: null,
  loginDetails: null,
  customerProfile: null,
  signupDetails: [],
  resetDetails: null,
  upcomingBookingDetails: null,
  pastBookingDetails: null,
  membershipDetails: null,
  savedCards: null,
  savedPaymentMethods: null,
  allGiftCards: null,
  profileImageUrl: null,
  paymentModes: [],
  relatedContactsList: [],
  emergencyContactsList: [],
  updateProfileStatus: null,
  paymentDetails: {},
  installments: {}
}

export default function(state = INITIAL_STATE, action) {
  const urlPrefix = '' + state.urlPrefix
  switch (action.type) {
    case SETURLPREFIX:
      return { ...state, urlPrefix: action.payload }
    case LOGIN:
      return { ...state, loginDetails: action.payload }
    case LOGOUT:
      return { ...state, ...INITIAL_STATE, urlPrefix }
    case CUSTOMERPROFILEDETAILS:
      return { ...state, customerProfile: action.payload }
    case UPDATE_USER_PROFILE:
      return { ...state, updateProfileStatus: action.payload }
    case GET_PROFILE_IMAGE:
      return { ...state, profileImageUrl: action.payload }
    case PROFILE_IMAGE_ERROR:
      return { ...state, profileImageUrl: null }
    case PAST_BOOKING_DETAILS:
      return { ...state, pastBookingDetails: action.payload }
    case UPCOMING_BOOKING_DETAILS:
      return { ...state, upcomingBookingDetails: action.payload }
    case MEMBERSHIP:
      return { ...state, membershipDetails: action.payload }
    case SIGNUP:
      return {
        ...state,
        loginDetails: action.payload,
        signupDetails: action.payload
      }
    case RESET:
      return { ...state, resetDetails: action.payload }
    case PAYMENTDETAILS:
      return { ...state, paymentDetails: action.payload }
    case INSTALLMENTS:
      return { ...state, installments: action.payload }
    case RELATEDCONTACTSLIST:
      return { ...state, relatedContactsList: action.payload }
    case EMERGENCYCONTACTSLIST:
      return { ...state, emergencyContactsList: action.payload }
    case SAVEDCARDS:
      return {
        ...state,
        savedCards:
          action.payload?.filter?.(
            mode =>
              ![
                PAYMENT_METHOD_TYPES.us_bank_account,
                PAYMENT_METHOD_TYPES.sepa_debit
              ].includes(mode.type)
          ) || []
      }
    case SAVED_PAYMENT_METHODS:
      return { ...state, savedPaymentMethods: action.payload }
    case PAYMENTMODES:
      return { ...state, paymentModes: action.payload }
    case VERIFY:
      return { ...state, verifyDetails: action.payload }
    case CONFIRM:
      return { ...state, confirmDetails: action.payload }
    case GENERATE:
      return { ...state, generateDetails: action.payload }
    case ALL_GIFT_CARDS:
      return { ...state, allGiftCards: action.payload }
    default:
      return state
  }
}
