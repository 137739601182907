import React from 'react'
import styled from 'styled-components'
import ErrorBoundaryIcon from 'Components/Icons/ErrorBoundary'

const Container = styled.div`
  min-height: 40vh;
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 3rem;
  max-height: 70vh;
  font-family: ${({ theme }) => theme?.fontFamily ?? 'Arial'};
  text-align: center;
  & > svg {
    margin: 32px 0;
  }
  & > p {
    margin-bottom: 8px;
  }
  @media (max-width: ${({ theme }) => theme?.breakpoint?.md ?? '768'}px) {
    max-height: 60vh;
  }
`

const Header = styled.h1`
  font-size: 30px;
  font-weight: bolder;
  color: black;
  margin-bottom: 16px;
`

const SubText = styled.p`
  font-size: 18px;
  color: #546a7b;
  padding-bottom: 60px;
  max-width: 540px;
  margin-top: 0;
`

const UnderService = () => (
  <Container>
    <ErrorBoundaryIcon />
    <Header>System is under maintenance</Header>
    <SubText>We&apos;re preparing to serve you better.</SubText>
  </Container>
)

export default UnderService
