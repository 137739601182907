export const bookee_viewed_registration = 'bookee_viewed_registration'
export const bookee_completed_registration = 'bookee_completed_registration'
export const bookee_complete_waiver_form = 'bookee_complete_waiver_form'
export const bookee_add_card = 'bookee_add_card'
export const bookee_schedule = 'bookee_schedule'
export const bookee_trainer_schedule = 'bookee_trainer_schedule'
export const bookee_facility_schedule = 'bookee_facility_schedule'
export const bookee_course_schedule = 'bookee_course_schedule'
export const bookee_viewed_videos = 'bookee_viewed_videos'
export const bookee_memberships_schedule = 'bookee_memberships_schedule'
export const bookee_viewed_product = 'bookee_viewed_product'
export const bookee_viewed_cart = 'bookee_viewed_cart'
export const bookee_played_video = 'bookee_played_video'
export const bookee_add_product = 'bookee_add_product'
export const bookee_checkout_cart = 'bookee_checkout_cart'
export const bookee_booked_class = 'bookee_booked_class'
export const bookee_booked_appointment = 'bookee_booked_appointment'
export const bookee_purchased_course = 'bookee_purchased_course'
export const bookee_viewed_pricing_option = 'bookee_viewed_pricing_option'
export const bookee_viewed_gift_card = 'bookee_viewed_gift_card'
export const bookee_purchased_pricing_option = 'bookee_purchased_pricing_option'
export const bookee_purchased_gift_card = 'bookee_purchased_gift_card'
export const bookee_purchased_booked_class = 'bookee_purchased_booked_class'
export const bookee_purchased_booked_appointment =
  'bookee_purchased_booked_appointment'
export const bookee_quickpay_appointment = 'bookee_quickpay_appointment'

export const eventCategoryOptions = {
  viewContent: 'ViewContent',
  addPaymentInfo: 'AddPaymentInfo',
  completeRegistration: 'CompleteRegistration',
  actionName: 'ActionName'
}
