import React from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate} 2s linear infinite;
  border: solid 2px #f2f0f536;
  border-top: solid 2px #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0 auto;
`

export default Spinner
