import {
  FETCHING_EVENTS,
  EVENTS,
  TRAINERS,
  EVENTDETAILS,
  NEXTEVENTDATE,
  BOOK,
  CLEARSTATUS,
  DISCOUNT_DETAILS,
  CLASSES,
  ROOMS,
  SETFROMEVENT,
  CLEARFROMEVENT
} from 'Constants'

const INITIAL_STATE = {
  events: [],
  nextEventDate: null,
  eventDetail: null,
  trainers: [],
  rooms: [],
  class_list: [],
  bookDetail: null,
  discountDetails: null,
  isFetchingEvents: false,
  from_event: { check: false, isAppointment: false, uri: null, data: null },
  eventFetchTimestamp: undefined
}

const actionIsValid = (state, data) => data >= state.eventFetchTimestamp

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCHING_EVENTS:
      return {
        ...state,
        isFetchingEvents: true,
        eventFetchTimestamp: action.timestamp
      }
    case EVENTS:
      if (actionIsValid(state, action.timestamp))
        return {
          ...state,
          events: action.payload,
          isFetchingEvents: false,
          eventFetchTimestamp: action.timestamp
        }
      return state
    case BOOK:
      return { ...state, bookDetail: action.payload }
    case CLEARSTATUS:
      return {
        ...state,
        eventDetail: null,
        events: [],
        nextEventDate: null,
        discountDetails: null,
        bookDetail: null
      }
    case EVENTDETAILS:
      return { ...state, eventDetail: action.payload }
    case NEXTEVENTDATE:
      return { ...state, nextEventDate: action.payload }
    case TRAINERS:
      return { ...state, trainers: action.payload }
    case ROOMS:
      return { ...state, rooms: action.payload }
    case CLASSES:
      return { ...state, class_list: action.payload || [] }
    case DISCOUNT_DETAILS:
      return { ...state, discountDetails: action.payload }
    case SETFROMEVENT:
      return {
        ...state,
        from_event: {
          check: true,
          uri: action.payload.uri,
          data: action.payload.eventData,
          isAppointment: action.payload.isAppointment
        }
      }
    case CLEARFROMEVENT:
      return { ...state, from_event: { check: false, uri: null, data: null } }
    default:
      return state
  }
}
