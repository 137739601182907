import { BREAKPOINT } from 'Constants'

const SECONDARY_TEXT_COLOR = '#8F8F8F'
const DARK_GREY = '#8F8F8F'
const LIGHT_GREY = '#D6D6D6'

const getTheme = ({ brand, bg, primaryText, fontFamily }) => {
  return {
    color: {
      brand,
      bg,
      primaryText,
      secondaryText: SECONDARY_TEXT_COLOR,
      borderDark: DARK_GREY,
      borderLight: LIGHT_GREY,
      success: '#45D67F',
      error: '#E65055'
    },
    fontFamily: `${fontFamily}`,
    spacingUnit: 0.5,
    breakpoint: {
      xl: BREAKPOINT.xlarge,
      lg: BREAKPOINT.large,
      md: BREAKPOINT.medium,
      sm: BREAKPOINT.small
    }
  }
}

export default getTheme
