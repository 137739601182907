import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Dweb from './Dweb'
import Mweb from './Mweb'
import { getUserProfile } from '../../actions/account_action'
import { MENU_ITEMS } from '../../constants'
import { overrideLabel } from '../../utils/client'

const hideNavbarRoutes = [
  'document',
  'reset-password',
  'create-password',
  'checkout',
  'forms',
  'confirm-payment',
  'unsubscribe',
  'unsubscribed',
  'resubscribed',
  'adyen-add-card'
]

const NavBar = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [isMweb, setMweb] = useState(true)
  const customerProfile = useSelector(
    store => store.accountreducer.customerProfile
  )
  const navbarSettings = useSelector(
    store => store.centerreducer?.regionsDetails?.client?.navbar_settings
  )
  const regions = useSelector(
    store => store.centerreducer.regionsDetails?.regions ?? []
  )

  const menuOptions = useMemo(
    () =>
      navbarSettings
        ?.sort((a, b) => a.order - b.order)
        .map((item, index) => {
          if (item.slug === MENU_ITEMS.appointments) {
            return {
              ...item,
              title: overrideLabel({
                key: 'label_appointment',
                value: 'Appointments'
              }),
              index
            }
          }
          if (item.slug === MENU_ITEMS.courses) {
            return {
              ...item,
              title: overrideLabel({
                key: 'label_course',
                value: 'Courses'
              }),
              index
            }
          }
          if (item.slug === MENU_ITEMS.classes) {
            return {
              ...item,
              title: overrideLabel({
                key: 'label_class',
                value: 'Classes'
              }),
              index
            }
          }
          return { ...item, index }
        }),
    [navbarSettings]
  )

  const hideIndex = useMemo(
    () =>
      hideNavbarRoutes.findIndex(route => location?.pathname?.includes(route)),
    [location?.pathname]
  )

  useEffect(() => {
    setMweb(window.innerWidth < 768)
  }, [])

  useEffect(() => {
    dispatch(getUserProfile())
  }, [dispatch])

  const getRegion = useCallback(() => {
    const route = location?.pathname
    const regex = /\/r\/(\d+)(?:\/|$)/
    const match = RegExp(regex).exec(route)

    if (match) {
      const region = match[1]
      return region
    } else {
      return null
    }
  }, [location?.pathname])

  useEffect(() => {
    if (regions?.length > 0) {
      localStorage.setItem('region_id', getRegion() || regions[0]?.id)
    }
  }, [regions, getRegion])

  useEffect(() => {
    // only for godaddy and wix websites that do not support links with params
    if (navbarSettings?.length > 0) {
      const homeCenterId = customerProfile?.data?.home_center_id

      if (homeCenterId) {
        localStorage.setItem('selected_center_id', homeCenterId)
        localStorage.setItem(
          'region_id',
          customerProfile?.data?.home_center?.region_id
        )
      } else if (regions?.[0]?.centers?.length === 1) {
        localStorage.setItem('selected_center_id', regions[0].centers?.[0]?.id)
        localStorage.setItem('region_id', regions[0].id)
      }
    }
  }, [customerProfile, navbarSettings, regions])

  if (!navbarSettings || navbarSettings.length === 0 || hideIndex > -1)
    return null

  return isMweb ? <Mweb items={menuOptions} /> : <Dweb items={menuOptions} />
}

export default NavBar
