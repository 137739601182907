import { persistStore, persistReducer } from 'redux-persist'
import reducers from './reducers/index'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import reduxThunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  version: '0.0.8',
  storage,
  blacklist: [
    'customFormReducer',
    'cartReducer',
    'eventreducer',
    'addcardReducer',
    'plan_reducer',
    'isLoggedReducer',
    'vodReducer',
    'waiverReducer'
  ]
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(
  persistedReducer,
  {},
  composeWithDevTools(applyMiddleware(reduxThunk))
)
const persistor = persistStore(store)

export { store, persistor }
