import React from 'react'
import styled from 'styled-components'
import LoaderIcon from 'Components/Icons/Loader'

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  width: 80px;
  margin: auto;
  position: relative;
  & div {
    width: 60px;
    height: auto;
  }
`

const Loader = () => {
  return (
    <LoaderContainer>
      <div>
        <LoaderIcon />
      </div>
    </LoaderContainer>
  )
}

export default Loader
