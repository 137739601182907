import {
  FETCHING_PRODUCTS,
  FETCHING_PRODUCT_DETAIL,
  GET_PRODUCTS,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_ERROR,
  GET_PRODUCTS_ERROR,
  ADD_PRODUCT,
  ADD_PRODUCT_ERROR,
  CLEAR_PRODUCTS,
  STOP_FETCHING_PRODUCT_DETAIL,
  CART_CHECKOUT,
  GET_BRANDS,
  GET_BRANDS_ERROR,
  STRIPE_SESSION_CART,
  THREE_D_SECURE_CART,
  CLEAR_CHECKOUT_STATUS
} from 'Constants'

const initialState = {
  isFetching: false,
  productDetail: null,
  products: [],
  actionStatus: null,
  error: null,
  isAdding: false,
  checkoutStatus: null,
  brands: [],
  stripeSessionData: null,
  threeDSecureData: null
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCHING_PRODUCTS:
      return { ...state, isFetching: true, error: null }
    case FETCHING_PRODUCT_DETAIL:
      return { ...state, isFetching: true, productDetail: null, error: null }
    case GET_PRODUCT_DETAIL:
      return { ...state, productDetail: payload }
    case STOP_FETCHING_PRODUCT_DETAIL:
      return { ...state, isFetching: false }
    case GET_PRODUCT_ERROR:
      return {
        ...state,
        isFetching: false,
        productDetail: null,
        error: payload
      }
    case GET_PRODUCTS:
      return { ...state, isFetching: false, products: payload, error: null }
    case GET_PRODUCTS_ERROR:
      return { ...state, isFetching: false, products: [], error: payload }
    case ADD_PRODUCT:
      return { ...state, isAdding: true, actionStatus: payload, error: null }
    case ADD_PRODUCT_ERROR:
      return { ...state, isAdding: false, actionStatus: null, error: payload }
    case GET_BRANDS:
      return { ...state, brands: payload }
    case GET_BRANDS_ERROR:
      return { ...state, brands: [], error: payload }
    case CART_CHECKOUT:
      return { ...state, checkoutStatus: payload }
    case CLEAR_CHECKOUT_STATUS:
      return { ...state, checkoutStatus: null }
    case CLEAR_PRODUCTS:
      return {
        ...state,
        products: [],
        productDetail: null,
        actionStatus: null,
        error: null
      }
    case STRIPE_SESSION_CART:
      return { ...state, stripeSessionData: payload }
    case THREE_D_SECURE_CART:
      return { ...state, threeDSecureData: payload }
    default:
      return state
  }
}
