import { client } from './client'
const axios = client()
import { CENTER, LOGIN, SEARCHCENTER, REGIONS } from 'Constants'
import { isLocalhost } from 'CommonComponents'
import { API_ROOT, SEARCH_REDIRECT, ENVIRONMENT } from 'Config'
import { store } from '../store.index'

let axiosConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('customerkey')
  }
}

export function getCenters() {
  let requestUrl = `${API_ROOT}/api/embed/centers`
  axiosConfig.params = {
    url_prefix: store.getState().accountreducer.urlPrefix
  }
  let request = axios.get(requestUrl, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        localStorage.setItem('center', data.data.centers[0].id)
        localStorage.setItem('currency', data.data.centers[0].currency.symbol)
        dispatch({ type: CENTER, payload: data.data })
      })
      .catch(err => {
        /** if (ENVIRONMENT === "development") location.href = "/search";
        else location.href = SEARCH_REDIRECT + ".getstudioyou.com"; **/
      })
  }
}

export function getRegions() {
  let requestUrl = `${API_ROOT}/api/embed/regions`
  axiosConfig.params = {
    url_prefix: store.getState().accountreducer.urlPrefix
  }
  let request = axios.get(requestUrl, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        localStorage.setItem('clientSettings', JSON.stringify(data.data.client))
        localStorage.setItem('currency', data.data.regions[0].currency.symbol)
        // localStorage.setItem('region_id', data.data?.regions?.[0]?.id)
        // if (data.data?.regions?.[0]?.centers?.length === 1) {
        //   localStorage.setItem(
        //     'selected_center_id',
        //     data.data.regions[0].centers[0].id
        //   )
        // }
        dispatch({ type: REGIONS, payload: data.data })
      })
      .catch(err => {
        /** if (ENVIRONMENT === "development") location.href = "/search";
        else location.href = SEARCH_REDIRECT + ".getstudioyou.com"; **/
      })
  }
}

export function searchCenter(postData) {
  let requestUrl = `${API_ROOT}/api/embed/search`
  axiosConfig.params = postData
  let request = axios.get(requestUrl, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        dispatch({ type: SEARCHCENTER, payload: data })
      })
      .catch(err => {
        dispatch({ type: SEARCHCENTER, payload: err.message })
      })
  }
}
